angular.module('app').filter('cancellationReason', function () {
    return function (reason) {
        var text;

        if (_.isUndefined(reason)) return text;

        switch (reason) {

            case "TACR":
                text = "Taken a closer ride";
                break;
            case "DDC":
                text = "Disputed collection";
                break;
            case "JNA":
                text = "Job not accepted";
                break;
            case "COP":
                text = "Change of plans";
                break;
            default:
                text = "Cancelled after driver enroute";
        }

        return text;

    };
});
